@import "../../mixins";

#home {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .home-content {
    padding: 16px 24px 34px;
  }

  .points-area {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 40em;
  }

  .user-points {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 1em;
    padding: 14px 16px 52px 16px;
    max-width: 550px;

    .user {
      display: grid;
      grid-template-columns: 34px auto 34px;
      justify-items: center;
      align-items: center;

      .user-image {
        display: inline-block;
        width: 34px;
        height: 34px;
      }

      .user-edit {
        .edit-button {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          width: 34px;
          height: 34px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .user-name {
        overflow: hidden;
        height: 1.2em;
        white-space: nowrap;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        color: #fff;
        text-overflow: ellipsis;
        width: 100%;
        padding-left: 12px;
        padding-right: 7px;
      }
    }

    .points {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 1em;
      grid-row-gap: 1em;
      margin-top: 20px;
      padding-left: 12px;
      padding-right: 12px;

      :first-child {
        grid-row: 1 / 3;
      }
    }


  }

  .button-placement {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: -27px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .no-games-text {
    text-align: center;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    //@include shadow-text;
    margin-top: 13px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;

    a {
      color: #fff;
    }
  }

  .leaderboard-slider-wrapper {
    margin-top: 16px;
  }

  .shop-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 34px;
  }

  .game-store {
    margin-top: 42px;
    position: relative;
  }

  .stream-service {
    width: 100%;
    margin-top: auto;
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  #home {
    .user-points {
      .points {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .button-placement {
      padding-left: 0;
      padding-right: 0;
    }

    .shop-area {
      margin-top: 24px;
      padding-left: 0;
      padding-right: 0;
    }

    .game-store {
      margin-top: 20px;
    }
  }
}
