#modal-time-limited-boost {
  position: relative;
  z-index: 20;

  .modal__content {
    margin: auto;
    max-width: 327px;
    text-align: center;
    background-color: transparent;
    position: relative;
  }
}

.time-limited-boost-container {
  display: grid;
  grid-template-rows: 205px 1fr;
  justify-items: center;
  background-color: #FFFFFF;
  border-radius: 16px;

  .icon-diamond {
    position: absolute;
    z-index: 2;
    left: -3%;
    top: -8%;
  }

  .icon-coin {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: -26%;
    margin: auto;
  }

  .icon-heart {
    position: absolute;
    z-index: 2;
    right: -3%;
    top: -18%;
  }

  .image-wrapper {
    position: absolute;
    top: -12%;
    left: 0;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  .time-limited-boost-header {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 1em 1em 0px 0px;
    background: linear-gradient(180deg, #B042AD 45.83%, #D08ECE 100%);
    clip-path: ellipse(100% 100% at top center);

    .header-image {
      display: block;
      max-width: 100%;
      height: 100%;
      margin-top: -1px;
    }
  }

  .time-limited-boost-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 30px;
    padding-bottom: 28px;
    width: 100%;
    text-align: center;

    .time-limited-boost-subtitle {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 153px;
      height: 24px;
      margin-top: 17px;
      line-height: 22px;
      background: rgba(203, 206, 215, .15);
      border-radius: 100px;
      padding: 1px 8px;
      margin-bottom: 14px;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 16px;
        font-weight: 900;
        line-height: 21.82px;
      }

      img {
        height: 32px;
        margin-left: 4px;
        margin-right: -2px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }

    .time-limited-boost-title {
      font-size: 22px;
      font-weight: 900;
      line-height: 26px;
    }

    .time-limited-boost-text {
      max-width: 225px;
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;

      b,
      strong {
        display: block;
        font-weight: 900;
      }
    }

    .share-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      row-gap: 1em;
    }
  }
}
