@import "../../../../mixins";

.happy-hour-content {
  display: block;

  .happy-hour-top-area {
    padding: 28px 34px 36px;
  }

  .happy-hour-title {
    width: 100%;
    margin-bottom: 9px;
    font-size: 44px;
    font-weight: 900;
    line-height: 44px;
    letter-spacing: 0.1599999964237213px;
    text-align: center;
    background: linear-gradient(180deg, #FFB0A6 0%, #C40202 100%);
    background-clip: text;
    color: transparent;
  }

  .happy-hour-description {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }
}
