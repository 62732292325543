#border-unlocked {
  background: linear-gradient(180deg, #9383E5 0%, #705BDD 100%);
  height: 100%;

  &.modal-open ~ div {
    opacity: 0;
  }

  p {
    margin: 0;
  }

  .modal {
    padding-top: 20%;
    overflow: hidden;
    max-height: 100vh;
  }

  .modal__content {
    font-weight: 900;
    margin: auto;
    max-width: 327px;
    text-align: center;
    background-color: transparent;
    position: relative;

    > * {
      position: relative;
      z-index: 1;
    }

    .bg-modal {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .rays {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
    }

    .rays-top {
      top: -38%;
      z-index: -1;
    }

    .rays-bottom {
      bottom: -30%;
      z-index: -1;
    }

    .content {
      display: inline-block;
    }

    .level {
      display: flex;
      justify-content: center;
      margin-top: -8%;
      margin-bottom: 50px;

      > img {
        max-width: 100%;
        filter: drop-shadow(0px 20px 16px rgba(0, 0, 0, 0.25));
      }

      p {
        font-size: 80px;
        line-height: 109px;
        position: absolute;
        top: 10%;
        color: #fff;
        text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
      }

      .user-image {
        position: absolute;
        top: -100%;
        width: 120px!important;
        height: 120px!important;
      }
    }

    .title-modal {
      font-size: 44px;
      line-height: 1;
      background: linear-gradient(180deg, #FFB0A6 0%, #C40202 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 16px;
    }

    .subtitle-modal {
      font-size: 22px;
      line-height: 1.2;
      margin-bottom: 16px;
      width: 128px;
      margin-left: auto;
      margin-right: auto;
    }

    .description {
      font-weight: 600;
      font-size: 22px;
      line-height: 1.25;
      max-width: 270px;
      margin-left: auto;
      margin-right: auto;

      strong {
        font-weight: 900;
      }
    }

    .next-level-border {
      width: 120px;
    }

    .btn-container {
      transform: translateY(50%);
    }
  }
}

@media (max-width: 360px) {
  #border-unlocked {
    .modal__content {
      .rays-bottom {
        transform: translateY(16%);
      }

      .level {
        margin-bottom: 30px;

        .user-image {
          width: 100px!important;
          height: 100px!important;
        }

        > img {
          height: 60px;
        }
      }

      .title-modal {
        margin-bottom: 10px;
      }

      .description {
        max-width: 215px;
      }

      .btn-container {
        transform: translateY(32%);
      }

      .button {
        height: 46px;
        border-radius: 13px;

        span {
          font-size: 17px;
          line-height: 23px;
        }
      }
    }
  }
}
