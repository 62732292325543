@import "../../../mixins";

.leaderboard-row {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.35);
  border-radius: 16px;
  // width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  border: 2px solid #303030;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  background: linear-gradient(180deg, #FFFFFF 0%, #D8D8D8 100%);

  &.leaderboard-row-gold {
    background: linear-gradient(180deg, #FCDD3D 0%, #F9A00D 100%);
  }

  &.leaderboard-row-success {
    background: linear-gradient(180deg, #8F0000 0%, #FC0000 100%);

    .slot-right {
      color: #FFFFFF;
      @include shadow-text;

      p {
        font-size: 18px;
      }
    }

    .points {
      font-size: 13px;
    }
  }

  &.user-leaderboard-position {
    .slot-right {
      p {
        font-size: 20px;
      }
    }

    .points {
      font-size: 14px;
    }
  }

  .slot-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;

    .leaderboard-player-position-number {
      width: 1.8em;
      text-align: center;
    }
  }

  .slot-right {
    margin-left: 15px;
    font-weight: 900;
    line-height: 1.4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    flex: 1;

    > div {
      line-height: 1.3;
      margin-right: 16px;
    }

    img {
      height: 30px;
    }
  }

  .points {
    font-size: 10px;
    font-weight: 900;
  }

  .small-avatar {
    width: 30px !important;
    height: 30px !important;
  }
}

.user-list {
  margin-left: 24px;
  margin-right: 24px;
}

.user-leaderboard-position {
  width: 100%;
  max-width: 640px;
  height: 56px;
  bottom: 0;
  position: absolute;
  z-index: 1;
  border-radius: 16px 16px 0 0px;
  margin-left: 0em;
  margin-right: 0em;

  .slot {
    color: white;
  }
}

.user-leaderboard-champion {
  position: sticky;
  top: 0;
  z-index: 1;
  margin-left: 16px;
  margin-right: 16px;
  background: linear-gradient(180deg, #FCDD3D 0%, #F9A00D 100%);

}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .leaderboard-row {
    .slot-right {
      margin-left: 10px;

      p {
        font-size: 15px;
      }

      img {
        height: 20px;
      }
    }
  }
}
