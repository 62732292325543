.modal-steps-container {
  background: #E7E7E7;
  border: 2px solid #fff;
  border-radius: 16px;
  padding: 27px 20px 20px 20px;
  position: relative;
  width: 295px;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;

    div {
      width: 24px;
      height: 24px;
    }

    img {
      width: 10px;
    }
  }

  .title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
  }

  .content {
    font-size: 14px;
    font-weight: 800;
    line-height: normal;
  }

  .footer {
    margin-top: 27px;
    display: flex;
    align-items: flex-end;
  }

  .step-summary {
    padding-bottom: 4px;
    flex: 1;
    color: #9FA0A3;
    font-size: 14px;
    font-weight: 700;
    padding-right: 15px;
  }

  .modal-steps-buttons {
    display: flex;
    gap: 8px;
  }

  .modal-steps-btn {
    min-width: 86px;
    height: 38px;
    border-radius: 11px;

    span {
      font-size: 12px;
      line-height: 1;
      top: 10px;
    }
  }
}

[x-placement="bottom"] {
  .__floater__arrow {
    margin-top: 2px;

    svg {
      filter: drop-shadow(0px 0px 0px #fff)
      drop-shadow(0px -2px 0px #fff)
      drop-shadow(0px 0px 0px transparent)
      drop-shadow(0px 0px 0px #fff);
    }
  }
}


[x-placement="top"] {
  .__floater__arrow {
    margin-bottom: 2px;

    svg {
      filter: drop-shadow(0px 0px 0px #fff)
      drop-shadow(0px 2px 0px #fff)
      drop-shadow(0px 0px 0px transparent)
      drop-shadow(0px 0px 0px #fff);
    }
  }
}
