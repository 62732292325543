.modal-presence-wrapper {
  position: relative;
  z-index: 20;
}

.modal-open {
  position: relative;
  z-index: 2;
}

.modal {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  padding: 24px;
  overflow: auto;
  background-color: rgba($color: #000000, $alpha: 0.7);

    &.active {
        opacity: 1;
        visibility: visible;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;
    }

  &__content {
    width: 100%;
    margin: 15% auto;
    background-color: #fff;
    position: relative;
    transition: inherit;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100px);
    border-radius: 1em;
    max-width: 30em;
    z-index: 1;
  }

    &.active > &__content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    &__header,
    &__body,
    &__footer {
        padding: 16px 20px;
    }

  .button-close {
    position: absolute;
    right: -0.7em;
    top: -0.7em;
  }

  .lights {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    z-index: 1;
  }

  .lights-top {
    top: 0;
  }

  .lights-bottom {
    bottom: 0;
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .modal {
    padding: 16px;
  }
}
