.find-item-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 20px auto;

  .object-to-find {
    position: relative;
    z-index: 1;
    height: 61px;
    min-width: 170px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding-left: 18px;
    padding-right: 7.5px;
    border-radius: 100px;
    border: 2px solid var(--secondary-gray-light, #E9EBF0);
    background: var(--primary-white, #FFF);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);

    img {
      max-width: 72px;
      margin-top: -8px;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 15px;
      color: #303030;
    }
  }

  .scene-wrapper {
    position: relative;
    margin-top: -20px;

    > img {
      max-width: 100%;
      height: auto;
      display: block;
    }

    .circle {
      position: absolute;
      border: 2px solid #8F0000;
      border-radius: 50%;

      &.green {
        border-color: #0E884E;
      }
    }

    .dot {
      position: absolute;
      left: 23px;
      top: 23px;
      background: red;
      height: 22px;
      width: 22px;
      display: block;
    }
  }
}
