@import "../../../mixins";

.modal-promotional-video {
  width: 100%;
  padding-bottom: 100%;

  &.video-mode {
    padding-bottom: 0;

    .modal-content {
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .modal-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
    border-radius: 16px;
    overflow: hidden;
  }

  .modal-video {
    width: 100%;
    max-width: 100%;
    margin: auto;
    padding: 4px;
    border-radius: 16px;
  }

  .modal-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 16px;
    background-size: cover;

    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .title {
    max-width: 238px;
    margin-top: 21px;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 900;
    line-height: 25px;
    @include shadow-text;
    color: #FFFFFF;
  }

  .play-btn {
    position: relative;
    z-index: 1;
    display: block;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    max-width: 300px;
    margin-top: auto;
    margin-bottom: 25px;
  }
}
