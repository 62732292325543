@import "../../../mixins";

.ladder-button-container {
    display: flex;
    align-items: center;
  width: 100%;
  flex-wrap: wrap;

    .ladder-button {
      position: relative;
        z-index: 2;
      width: 100% ;

      .button-inner-overlay span {
        justify-content: flex-start;
        filter: none;
        text-shadow: none;
      }

        .count-start {
            position: initial;
            display: flex;
            align-items: center;
            margin-top: -5px;

            .icon-position {
                margin-left: 30px;
                padding-right: 15px;

                img {
                    height: 25px;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }
            }

            .count {
              transition: all .3s ease-in-out;
                margin-top: -2px;
            }
        }

        .count-end {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            width: 70px;
            flex-direction: row;
            right: 0px;
            align-items: center;
            margin-top: -8px;

          .prize {
            position: absolute;
            max-width: 72px;
            z-index: 1;
            rotate: 8deg;
            left: -54px;
            top: -5px;

            & + .end-icon {
              transform: rotate(-13deg);
            }
          }

            .end-icon {
                height: 30px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }

        }


      &.button-gold .count {
        transition: none;
      }

      &.button-ladder-yellow .count {
        color: #000;
      }

      &.button-success .count,
      &.button-gold .count,
      &.button-success-gold .count, {
        color: #fff;
        @include shadow-text;
      }

      &.button-success-play {
        height: 60px;

        .count {
          font-size: 32px;
          @include shadow-text;
        }
      }
    }
}

.selector-label {
    width: 68%;
    height: 31px;
    background: #FFFFFF;
    box-shadow: inset 0px -6px 0px rgba(84, 84, 84, 0.6);
  border-radius: 0px 0px 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  box-sizing: border-box;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: 2px solid #545454;
    border-radius: 0px 0px 16px 16px;
  }

    h4 {
        text-transform: uppercase;
        font-weight: 900;
    }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .ladder-button-container {
    .ladder-button:not(.button-gold) {
      height: 53px;

      .count-start {
        margin-top: -7px;
      }
    }
  }
}
