.modal-presence-wrapper {
  .modal__content {
    background-color: transparent;
  }
}

.modal-genie-container {
  // background-image: url('../../../../public/img/genie/lamp-clouds.png');
  // background-size: contain;
  // background-repeat: no-repeat;
  // height: 390px;
  // width: auto;
  display: block;
  max-width: 100%;
  margin: 24px;

  .genie-content-container {
    background-color: #fff;
    min-height: 200px;
    padding: 24px;
    padding-bottom: 90px;
    border-radius: 16px;
    width: auto;
    max-width: 327px;
    margin-left: auto;
    margin-right: auto;

    img {
      max-width: 100%;
      max-height: 100px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .genie-lamp-container {
    max-width: 327px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -160px;
    position: relative;

    .genie-lamp {
      max-width: 100%;
    }

    .genie {
      position: absolute;
      right: 16px;
      bottom: 40px;
      max-height: 70%;
      -webkit-animation: floating 1s ease-in-out infinite;
      animation: floating 1s ease-in-out infinite;
    }
  }

  .modal-genie-content {
    font-weight: 900;
    font-size: 20px;
    line-height: 28px;

    .genie-answer {
      color: #B042AD;
      font-size: 28px;
      line-height: 38px;
    }

    .genie-text {
      z-index: 1;
      position: relative;
    }

    .genie-thank-you {
      margin-top: 16px;
      text-transform: uppercase;
    }
  }
}


@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .modal-genie-container {
    margin: 10px;

    .genie-content-container {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 75px;
    }

    .modal-genie-content {
      font-size: 18px;
      line-height: 25px;

      .genie-content {
        font-size: 26px;
        line-height: 36px;
      }
    }

    .genie-lamp-container {
      .genie {
        bottom: 7px;
      }
    }
  }
}

