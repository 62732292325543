.modal-extra-coin {
  padding: 21px 22px 25px;

  .title {
    margin-top: 0;
    margin-bottom: 0;
    background: linear-gradient(180deg, #FFB0A6 0%, #C40202 100%);
    background-clip: text;
    font-size: 32px;
    font-weight: 900;
    line-height: 44px;
    letter-spacing: 0.1599999964237213px;
    text-align: center;
    color: transparent;
  }

  p {
    margin-top: 5px;
    font-size: 22px;
    font-weight: 900;
    line-height: 26px;
    text-align: center;
  }

  .coin {
    width: 79px;
    margin-top: 23px;
  }

  .button {
    margin-top: 40px;
  }
}
