.auth-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .stream-service {
    margin-top: auto;
    padding-top: 16px;
  }
}

.auth-content {
  margin-left: 24px;
  margin-right: 24px;

  * {
    font-family: "Nunito", sans-serif
  }

  .header-logo {
    display: flex;
    justify-content: center;
  }

  .auth-shell {

    .auth-card {
      min-height: 25vh;
      background-color: #ffffff;
      margin-top: 8px;
      width: 100%;
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);

      .card-content {
        padding: 24px 22px 16px 22px;
      }

      .form-container {
        padding-left: 2px;
        padding-right: 2px;
      }

      .card-footer {
        height: 100px;
        width: 100%;
        background: #999;
        border-radius: 47% 53% 1em 1em/47% 53% 1em 1em;
        display: flex;
        align-items: center;
        justify-content: center;

        .card-prizes {
          zoom: 0.8;
          padding-left: 1em;
          padding-right: 1em;
        }
      }
    }
  }
}

.auth-shell-content {
  display: flex;
  align-items: center;
  text-align: center;

  .form-title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 24px;
  }

  .form-input-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    label {
      font-style: normal;
      font-weight: 700;
      font-size: 0.8em;
      line-height: 19px;
      padding-bottom: 0.5em
    }

    .label {
      font-size: 14px;
    }

    .full-input {
      background: #E9EBF0;
      border: 2px solid #545454;
      box-shadow: inset 0px 6px 6px rgba(0, 0, 0, 0.3);
      border-radius: 100px;
      padding: 10px 16px;
      gap: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;

    }

    .prefix-input {
      display: flex;

      .prefix {
        background: #fff;
        border: 2px solid #545454;
        border-radius: 100px 20px 20px 100px;
        padding: 10px 16px;
        gap: 10px;
        margin-right: 0.2em;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
      }

      .half-input {
        background: #E9EBF0;
        border: 2px solid #545454;
        box-shadow: inset 0px 6px 6px rgba(0, 0, 0, 0.3);
        border-radius: 20px 100px 100px 20px;
        padding: 10px 16px;
        gap: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        width: 100%;
      }
    }

  }

  .notice {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 24px;
    margin-top: -14px;
  }

  .terms-area {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-top: 32px;
    padding-bottom: 26px;

    a {
      color: #5AB9FF;
      text-decoration: none;
    }
  }

  .submit {
    padding-top: 8px;
    filter: drop-shadow(0px 8px 16px #00000059);
  }

  .forgot-area {
    padding-top: 1em;

    .forgot-password {
      color: #5AB9FF;
      text-decoration: none;
      font-style: normal;
      font-weight: 700;
      font-size: 1em;
    }
  }

  .danger {
    label {
      color: #FF0101;
    }

    .prefix-input {
      .prefix {
        background: #FFEEEE;
        border: 2px solid #FF0101;
      }

      .half-input {
        background: #FFEEEE;
        border: 2px solid #FF0101;
      }
    }

    .full-input {
      background: #FFEEEE;
      border: 2px solid #FF0101;
    }
  }
}

.danger {
  .form-control {
    background: #FFEEEE;
    border: 2px solid #FF0101;
  }
}

.error-msg {
  color: #FF0101;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-top: 0.4em;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    padding-right: 0.3em;

    .color-svg path {
      fill: red;
    }
  }
}

.no-account-area {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 16px;

  .no-account {
    font-style: normal;
    font-weight: 800;
    font-size: 1em;
    margin-bottom: 8px;
    color: #FFFFFF;
  }

  .button-submit {
    display: flex;
    gap: 20px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    filter: drop-shadow(0px 8px 16px #00000059);
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .auth-content {
    margin-left: 16px;
    margin-right: 16px;

    .title-nav {
      margin-top: 16px;
      margin-bottom: 6px;
    }

    .auth-shell-content {
      .auth-card {
        .card-content {
          padding: 16px;
          min-height: 265px;
        }

        .form-input-row {
          margin-bottom: 8px;
        }

        .card-footer {
          height: 90px;
        }
      }

      .notice {
        margin-top: 0;
      }

      .submit {
        padding-top: 2px;
      }

      .terms-area {
        margin-top: 20px;
        padding-bottom: 2px;
      }
    }

    .form-title {
      font-size: 18px;
      line-height: 25px;

      &.form-title-login {
        margin-bottom: 8px;
      }
    }
  }

  .no-account-area {
    margin-top: 12px;
    padding-bottom: 12px;

    .no-account {
      font-size: 14px;
      margin-bottom: 6px;
    }

    .button-submit {
      //width: 80%;
    }
  }
}
