#background {
  min-height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
    z-index: -999;

    .content {
        z-index: 1;
    }

    .background-white-blur {
        background: rgba(255, 255, 255, 0.45);
        vertical-align: baseline;
        height: 80%;
        width: 80%;
        filter: blur(150px);
        position: absolute;
        border-radius: 100px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

      @supports (-webkit-touch-callout: none) {
        width: 100%;
        height: 100%;
        border-radius: 0;
        filter: none;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .background-square {
        background-image: url(./img/square-pattern-background.svg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    &.defaultGame {
      background: linear-gradient(180deg, #B165D6 0%, #9D3ECC 100%);
    }

    &.generalknowledge {
        background:
            linear-gradient(180deg, #56D598 0%, #27AA6B 100%);
    }

    &.geography {
        background:
            linear-gradient(180deg, #A7A3FE 0%, #726EC7 100%);
    }

    &.sports {
        background:
            linear-gradient(180deg, #FF5383 0%, #A43453 100%);
    }

    &.history {
        background:
            linear-gradient(180deg, #4FB1FF 0%, #3477AC 100%);
    }

    &.music {
        background:
            linear-gradient(180deg, #D471CE 0%, #94478F 100%);
    }

    &.movies {
        background:
            linear-gradient(180deg, #B165D6 0%, #9D3ECC 100%);
    }

    &.lifestyle {
        background:
            linear-gradient(180deg, #30C3BC 0%, #27A29C 100%);
    }

    &.celebrity {
        background:
            linear-gradient(180deg, #FF952F 0%, #B6691F 100%);
    }

    &.religion {
        background:
            linear-gradient(180deg, #D5A165 0%, #A97F4E 100%);
    }

    &.home {
        background: #008CA3;
    }

    &.onboarding {
        background: #008CA3;
    }

    &.opponent {
        background: #008CA3;
    }

}
