.avatar-border {
  position: absolute;
  width: 210%;
  height: 210%;
  top: -55%;
  left: -55%;

  img {
    width: 100%;
    height: 100%;
  }
}
