@import "../../../mixins";

.coin-offer-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.popular-tag {
  background: linear-gradient(180deg, #FFD5A5 0%, #FF951D 100%);
  border-radius: 100px;
  padding: 4px 8px;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  transform: translateY(50%);
  text-transform: uppercase;
  z-index: 1;
}

.coin-offer {
  width: 100%;
  height: 92px;
  border-radius: 16px;
  padding-top: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 0px -6px 0px rgba(0, 0, 0, 0.25);


  .price-tag {
    color: #fff;
    font-weight: 900;
    font-size: 17px;
    line-height: 23px;
    @include shadow-text;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
      font-style: normal;
      font-size: 30px;
      line-height: 1.2;
      position: relative;
    }
  }


  &.coin-offer-orange {
    background: linear-gradient(180deg, #8F0000 0%, #FC0000 100%);
  }

  &.coin-offer-blue {
    background: linear-gradient(184.36deg, #9BD5FF 3.53%, #187FCB 96.46%);
  }

  &.coin-offer-purple {
    background: linear-gradient(180deg, #D471CE 0%, #484BA6 100%);
  }

  .popular-stars {
    background: url('../../../../public/img/popular-stars.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;

    &.happy-hour {
      &:after {
        content: "";
        display: block;
        background: url('../../../../public/img/discount-tags.png');
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }
  }
}

.coin-price {
  background: #FFFFFF;
  border: 2px solid #545454;
  box-shadow: inset 0px -6px 0px rgba(84, 84, 84, 0.6);
  border-radius: 0 0 16px 16px;
  width: 80%;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  padding-top: 5px;
  padding-bottom: 7px;
  margin-top: -2px;

}

.coin-offer-inner-overlay {
  width: 100%;
  height: 2em;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px;
  position: absolute;
  top: 0;
}
