.gameplay-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;


  .hint-time-wrapper {
    position: relative;
  }
}

.timer-animated {
  position: absolute;
  /*      position: absolute;
        bottom: 0;
        transform: rotate(30deg);
        left: 3px;
        width: 24px;*/
  z-index: 10;
}

.answer-wrapper {
  width: 100%;

  button {
    width: 100%;
  }
}

.options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  &.options-images {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.modal-presence-wrapper {
  .modal {
    padding: 0;
  }

  .modal__content {
    margin: 0;
    max-width: 100%;
  }

  .content {
    max-width: 100%;
  }
}
