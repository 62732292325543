.modal-promotion {
  width: 100%;
  padding-bottom: 100%;

  .modal-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
    border-radius: 16px;
    overflow: hidden;
  }

  .modal-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 16px;
    background-size: cover;
  }

  .logo {
    position: relative;
    z-index: 1;
    margin-top: 14px;
    margin-right: auto;
  }

  .title {
    position: relative;
    margin-top: auto;
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
  }

  .subtitle {
    position: relative;
    margin-top: 5px;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  .description {
    position: relative;
    max-width: 258px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: #ffffff;

    strong {
      display: block;
      font-weight: 700;
    }

    a {
      color: #ffffff;
    }
  }

  .modal-button {
    width: 100%;
    height: 48px;
    margin-top: 11px;
    line-height: 48px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 5px 0px #00000073;
    border-radius: 112px;

    span {
      display: block;
    }
  }
}
