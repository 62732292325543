@import "../../../mixins";

.leaderboard-slider {
  position: relative;
  padding: 7.65px 25px 13px 0;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  .swiper {
    padding-left: 25px;
  }

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 5;
    padding: 10px;
  }

  &__prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
  }

  &__next {
    right: 0;
  }

  &__prev_disabled {
    display: none;
  }

  &__next_disabled {
    display: none;
  }

  .leaderboard-item {
    display: block;
    text-align: center;
    padding-top: 7px;
    text-decoration: none;

    &__star {
      position: absolute;
      animation: rotating 5s linear infinite;
    }

    &__crown {
      position: absolute;
      top: -40%;
      z-index: 3;
      width: 90.48%;
    }

    &__avatar {
      position: relative;
      max-width: 63px;
      width: 100%;
      border-radius: 50%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__avatar-image {
      display: block;
      position: absolute;
      top: 4%;
      left: 0;
      right: 0;
      width: 88.9%;
      z-index: 2;
      margin: auto;
    }

    &__avatar-border {
      display: block;
      width: 100%;
      z-index: 1;
    }

    &__diamond {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 3;
      width: 66%;
      bottom: -20%;
    }

    &__rank {
      position: relative;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      @include shadow-text;
      z-index: 4;
    }

    &__score {
      margin-top: 13px;
      font-weight: 800;
      font-size: 12px;
      line-height: 16.37px;
      color: #fff;
      @include shadow-text;
    }

    &__name {
      width: 100%;
      margin: 0 auto;
      font-weight: 800;
      font-size: 12px;
      line-height: 16.37px;
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@keyframes rotating {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}
