.prizes-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .prizes-content {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    max-width: 500px;
    width: 100%;

    .prizes-items {
      display: flex;
      flex-direction: column;
      align-content: center;
      gap: 40px;
    }

    .prize-wrapper {
      position: relative;

      &:nth-child(2) {
        .prize-right-slot {
          .background-right {
            background: linear-gradient(184.36deg, #56D598 3.53%, #0E884E 96.46%);
          }
        }

        .prize-left-slot {
          .prize-title-small {
            color: #FF81A4;
          }
        }
      }

      &:nth-child(3) {
        .prize-right-slot {
          .background-right {
            background: linear-gradient(184.36deg, #9BD5FF 3.53%, #187FCB 96.46%);
          }
        }

        .prize-left-slot {
          .prize-title-small {
            color: #5AB9FF;
          }
        }
      }

      &:nth-child(4) {
        .prize-right-slot {
          .background-right {
            background: linear-gradient(180deg, #7175DB 0%, #36399A 100%);
          }
        }

        .prize-left-slot {
          .prize-title-small {
            color: #6138B9;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .prizes-container {

    .prizes-content {
      padding-left: 16px;
      padding-right: 16px;

      .prizes-items {
        gap: 30px;
      }
    }
  }
}
